<template>
  <div class="login-page page-top ">

    <div class="page-logo flex-column-center">
      <img :src="logoUrl" alt="">
      <span>Sign up for TikTok</span>
    </div>

    <div class="register-main">
      <div class="login-form">
        <div class="form-item ">
          <div class="input">
            <input type="text" v-model="form.username"  :placeholder="i18n.t('register.placeholder6')">
          </div>
        </div>

        <div class="form-item " v-show="register_phone">
          <div class="input">
            <input type="tel" v-model="form.Phone" :placeholder="i18n.t('register.placeholder1')">
          </div>
        </div>
        <div class="form-item ">
          <div class="input">
            <input :type="showPassword ? 'text' : 'password'" v-model="form.password" :placeholder="i18n.t('register.placeholder2')">
            <div class="adot" @click="showPassword = !showPassword">
              <img :src="eyeUrl" alt="" v-show="!showPassword">
              <img :src="eyeUrl2" alt="" v-show="showPassword">
            </div>
          </div>
        </div>
        <div class="form-item ">
          <div class="input">
            <input :type="showSafePassword ? 'text' : 'password'" v-model="form.safePassword" :placeholder="i18n.t('register.placeholder4')">
          <div class="adot" @click="showSafePassword = !showSafePassword">
            <img :src="eyeUrl" alt="" v-show="!showSafePassword">
            <img :src="eyeUrl2" alt="" v-show="showSafePassword">
          </div>
          </div>
        </div>
        <div class="form-item ">
          <div class="input">
            <input type="text" v-model="form.invite" :placeholder="i18n.t('register.placeholder3')">
          </div>
        </div>
        <div class="form-item  desc">
          <Checkbox v-model="checked" shape="square" icon-size="16">
            <div>
              <span>{{i18n.t('register.role1')}}</span>
              <span class="red">{{i18n.t('register.role2')}}</span>
              <span>{{i18n.t('register.role3')}}</span>
            </div>
          </Checkbox>
        </div>


      <div class="btn-box  flex-column-center">
        <Button type="info"  @click="submit">{{i18n.t('register.btn')}}</Button>
      </div>

      <div class="bottom-desc" >
        <span>{{i18n.t('register.account')}}?</span>&nbsp;
        <span class="red" @click="toLogin">{{i18n.t('register.login')}}</span>
      </div>

      </div>
    </div>
  </div>
</template>
<script>
import { NavBar, Button , Checkbox,Dialog } from 'vant';
import {login, register} from '@/api/login'
import headerBar from '@/components/header'
import {setToken, setUser, setUserId} from "@/utils/auth";
import {customization, userInfo} from "@/api/user";
const FingerprintJS = require('@fingerprintjs/fingerprintjs')

// Initialize the agent at application startup.
const fpPromise = FingerprintJS.load()
export default {
  components: {
    NavBar,
    Button,
		Checkbox,
    headerBar,
    Dialog,
  },
  data() {
    return {
      logoUrl: require('../../assets/imgs/icon-register.png'),
      eyeUrl: require('../../assets/imgs/icon-eye.png'),
      eyeUrl2: require('../../assets/imgs/icon-eye2.png'),
      kefuUrl: require('../../assets/imgs/icon-kefu3.png'),
      register_remark: false,
      showPassword: false,
      showSafePassword: false,
      form: {
        IdToString: '',
        username: '',
        password: '',
        safePassword: '',
        Fingerprint: '',
        Phone: '',
        invite: ''
      },
			checked: false,
    }
  },
  // register_phone
  computed: {
    register_phone() {
      return this.$store.state.register_phone
    }
  },
  created() {},
  mounted() {
    let code = this.$route.query.code
    if (code) {
      this.form.invite = code
    }
  },
  methods: {
    back() {
      this.$router.push({
        name: 'login'
      })
    },
		toLogin() {
			this.back()
		},
    handleInput() {
      // 只允许输入数字
      this.form.username = this.form.username.replace(/\D/g, '');
    },
    handleKeydown(event) {
      // 检查是否按下空格键，如果是则阻止默认行为
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    },
    submit() {

      if (!this.form.username) {
        this.$toast({
          message: this.i18n.t('register.placeholder1'),
          icon: 'warning-o'
        })
        return
      }
      if (!this.form.password) {
        this.$toast({
          message: this.i18n.t('register.placeholder2'),
          icon: 'warning-o'
        })
        return
      }
      if (!this.form.safePassword) {
        this.$toast({
          message: this.i18n.t('register.placeholder4'),
          icon: 'warning-o'
        })
        return
      }

      let form = new FormData()
      form.append('user_name', this.form.username)
      form.append('password', this.form.password)
      form.append('phone', this.form.Phone)
      form.append('invite', this.form.invite)
      form.append('SafePassword', this.form.safePassword)
      form.append('IdToString', this.form.IdToString)
      register(form).then(res => {
        if (res.code == 0) {
          let form = new FormData()
          form.append('user_name', this.form.username)
          form.append('password', this.form.password)
          form.append('token', true)
          login(form).then(res => {
            if (res.code == 0) {
              this.$toast.success(this.i18n.t('login.success'))
              setToken(res.data.token)
              setUser(this.form.username)
              setUserId(res.data.id)
              localStorage.setItem('username', this.form.username)
              if (this.checked) {
                localStorage.setItem('password', this.form.password)
              }
              // 注册弹出窗口
              if(this.$store.state.pop_window === "true") {
                let text = this.i18n.t("register.desc") + "\n"
                text += this.i18n.t("register.desc1") + "\n"
                text += this.i18n.t("register.desc2") + "\n"
                text += this.i18n.t("register.desc3") + "\n"
                text += this.i18n.t("register.desc4") + "\n"
                text += this.i18n.t("register.desc5") + "\n"
                text += this.i18n.t("register.desc6") + "\n"
                let title = this.i18n.t("register.desc_title")
                let ensure = this.i18n.t("register.btn1")
                // Inshallah|Shop
                // 使用 replace() 方法来替换文本
                // appName
                title = title.replaceAll("$1", this.$store.state.appName);
                text = text.replaceAll("$1", this.$store.state.appName);
                Dialog.alert({
                  title: title,
                  message: text,
                  messageAlign: 'left',
                  confirmButtonText: ensure,
                }).then(() => {
                  // on close
                  this.$router.push({
                    name: 'index'
                  })
                });
              } else {
                this.$router.push({
                  name: 'index'
                })
              }

            } else {
              this.$toast({
                message: res.msg,
                icon: 'warning-o'
              })
            }
          })
        } else {
          this.$toast({
            message: res.msg,
            icon: 'warning-o'
          })
        }
      })
    },
  }
}
</script>
